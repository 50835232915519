import React, { useState } from "react";
import { useEffect } from "react";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import "./LandingPage.scss";
import FsLightbox from "fslightbox-react";
import parse from "html-react-parser";
import ScrollAnimation from "react-animate-on-scroll";
import { Link as ScrollLink } from "react-scroll";
//swiper imports
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
//images
import { ReactComponent as Curvedesign } from "../../images/landing-page/curvedesign.svg";
import bannerhand from "../../images/landing-page/bannerhand.svg";
// import bannerimg from "../../images/landing-page/bannerimg.png";
// import mbbannerimg from "../../images/landing-page/mbbannerimg.png";
import bannerimg from "../../images/landing-page/banner_desk.png";
import mbbannerimg from "../../images/landing-page/banner_mob.png";
import shilpaimg from "../../images/landing-page/shilpaimg.png";
import aboutplayicon from "../../images/landing-page/aboutplayicon.svg";
import partdesignimg from "../../images/landing-page/partdesignimg.png";
import mbpartdesignimg from "../../images/landing-page/mbpartdesignimg.png";
import copyicon from "../../images/landing-page/copyicon.svg";
import formright from "../../images/landing-page/formright.jpg";
import dropDownArrow from "../../images/landing-page/drop-down-arrow.svg";
import uploadicon from "../../images/landing-page/uploadicon.png";
import fiftycontestclosed from "../../images/landing-page/50y_contest_closed-desk.png";
import fiftycontestclosedmob from "../../images/landing-page/50y_contest_closed-mob.png";


import whiteplayicon from "../../images/landing-page/whiteplayicon.png";
import redleftarrow from "../../images/landing-page/redleftarrow.svg";
import redrightarrow from "../../images/landing-page/redrightarrow.svg";
import config from "../../config/config";
import Joi from "joi";
import http from "../../helpers/http";
import testidesign from "../../images/landing-page/testidesign.png";
import galleryimg1 from "../../images/landing-page/galleryimg1.png";
import galleryimg2 from "../../images/landing-page/galleryimg2.png";
import galleryimg3 from "../../images/landing-page/galleryimg3.png";
import galleryimg4 from "../../images/landing-page/galleryimg4.png";
import galleryimg5 from "../../images/landing-page/galleryimg5.png";
import galleryplayicon from "../../images/landing-page/galleryplayicon.png";
import starimg from "../../images/landing-page/starimg.png";
import winnerimg1 from "../../images/landing-page/winnerimg1.png";
import winnerimg2 from "../../images/landing-page/winnerimg2.png";
import whitearrowleft from "../../images/landing-page/whitearrowleft.png";
import whitearrowright from "../../images/landing-page/whitearrowright.png";
import fbicon from "../../images/landing-page/fbicon.svg";
import Spinner from "../../images/loader.gif";
import instaicon from "../../images/landing-page/instaicon.svg";
import youtubeicon from "../../images/landing-page/youtubeicon.svg";
import linkedinicon from "../../images/landing-page/linkedinicon.svg";
import laxmilogo from "../../images/landing-page/laxmilogo.svg";
import whiteinstaicon from "../../images/landing-page/whiteinstaicon.svg";
import whitefbicon from "../../images/landing-page/whitefbicon.svg";
import whiteyoutubeicon from "../../images/landing-page/whiteyoutubeicon.svg";
import whitelinkedinicon from "../../images/landing-page/whitelinkedinicon.svg";
import winnerbgimg from "../../images/landing-page/winnerbgimg.png";
import mbwinnerbgimg from "../../images/landing-page/mbwinnerbgimg.svg";
import instaleftdot from "../../images/landing-page/instaleftdot.png";
import instarightdot from "../../images/landing-page/instarightdot.png";
import hamburger from "../../assets/hamburger.svg";
import cross from "../../assets/cross.svg";
//hooks
import useWindowDimensions from "../../useWindowDimensions";
import LandingPageFooter from "../LandingPageFooter/LandingPageFooter";
import axios from "axios";
import MetaDecorator from "../../componentsnew/MetaDecorator/MetaDecorator";
import { testimonialData, weeklyWinners } from "./landingPageData";
// import { MyVerticallyCenteredModal } from "../../pages/Media";
import Slider from "react-slick";
import GoToTop from "../../components/GoToTop";
Swiper.use([Navigation, Autoplay]);

const hashtagData = [
  {
    hashTag: "#LaxmiYadoonKiRecipe",
    hashTagInfo:
      "Growing up we all have fond food memories. These memories stay with us throughout. For any home, food is a go-to savior. We would be thrilled to know your favorite desi food memory from the time you moved to the USA and the role it played in your immigration journey.  Use <span>#LaxmiYadoonKiRecipe</span> and tell us about how you recreate your favorite foods from home away and keep the traditions alive.",
  },
  {
    hashTag: "#50YearsOfLaxmi",
    hashTagInfo:
      "When South Asians started moving to North America in the hope of a brighter future, one thing that was surely missed was their home, family, and food. Since then, Laxmi has been the go-to and must-have for a lot of them; bringing families and friends together and helping two strangers bond over food.  For celebrating our 50th year, we’d love to hear the story about how you came across Laxmi products during your initial time here in US and how you decided that it was time to bring Laxmi home during your American journey.",
  },
  {
    hashTag: "#ReachOutWithLaxmi",
    hashTagInfo:
      "At Laxmi, we’ve come across stories of how the food and the brand have been that one binding factor that brings people together. Like welcoming a lost peer into your home and comforting them with a home-cooked comforting meal, inviting your new neighbors for a meal, or helping the community in general, food has always been a simple yet beautiful way to show love and connection. In our 50th year, we’d be honored to be a part of your <span>#ReachOutWithLaxmi</span> story about how you’ve reached out or how someone else has reached out to you with a comforting meal.",
  },
];

const galleryData = [
  {
    img: galleryimg1,
  },
  {
    ytLink: "https://www.youtube.com/embed/eve5GMV6yKw",
    img: galleryimg2,
    mediaType: "video",
  },
  {
    ytLink: "https://www.youtube.com/embed/eve5GMV6yKw",
    img: galleryimg3,
    mediaType: "video",
  },
  {
    img: galleryimg4,
  },
  {
    img: galleryimg5,
  },
];
const body = document.querySelector("body");

const LandingPage = (props) => {
  const { width } = useWindowDimensions();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");
  const [copyHashTag, setCopyHashTag] = useState(null);
  const [winnerType, setWinnerType] = useState("weekly-winner");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [instapost, setinstapost] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const [values, setValues] = useState({
    gender: "",
    name: "",
    email: "",
    phone: "",
    age: "",
    contest_learning: "",
    fav_product: "",
    product_want: "",
    story: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isTermsAccept, setIsTermsAccept] = useState(true);
  // const [filename, setfilename] = useState();

  let base_url = config.api_url + "/forms/landing-page";
  let sizeLimit = 300;

  let insta_api = config.insta_api;
  let insta_token = config.insta_token;
  let insta_limit = 12;
  let insta_fields =
    "id,media_type,media_url,username,timestamp,caption,permalink,thumbnail_url";

  const schema = Joi.object({
    name: Joi.string().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Phone"),
    gender: Joi.string().required().label("Gender"),
    age: Joi.number().required().label("Age"),
    contest_learning: Joi.string().required().label("Question"),
    fav_product: Joi.string().required().label("Question"),
    product_want: Joi.string().required().label("Question"),

    media: Joi.required().label("This"),
    story: Joi.string().required().label("Story"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.story) {
      if (!checkWordLen(values.story, 200)) {
        errors["story"] = `Only 200 word is allowed`;
      }
    }

    if (values.media) {
      if (values.media.size > sizeLimit * 1024 * 1024) {
        errors["media"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = ["image/jpeg", "image/png", "image/tiff", "video/mp4"];
      if (!allowedExt.includes(values.media.type)) {
        errors["media"] = "Please upload valid file type";
      }
    }

    return errors ? errors : null;
  };

  function checkWordLen(text, length) {
    var len = text.split(/[\s]+/);
    console.log("word length", len.length);
    if (len.length > length) {
      // alert(
      //   "You cannot put more than " + wordLen + " words in this text area."
      // );
      // oldValue = text != oldValue ? text : oldValue;
      // text = oldValue ? oldValue : "";
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(values)) {
      if (key == "phone") {
        formData.append(key, "+1" + data || "");
      } else {
        formData.append(key, data || "");
      }
    }
    formData.append("consent", isTermsAccept);
    const { data } = await http.post(base_url, formData);

    if (data) {
      // redirect
      // navigate("/thank-you");
      props.history.push("/50-years-contest/thankyou");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type == "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  useEffect(() => {
    var testimonial_swiper = new Swiper(
      ".swiper-container.testimonial_swiper",
      {
        spaceBetween: 70,
        navigation: {
          prevEl: ".testimonial_swiper_wrapper .left_arrow",
          nextEl: ".testimonial_swiper_wrapper .right_arrow",
        },
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1.7,
            spaceBetween: 70,
          },
          992: {
            slidesPerView: 2.3,
          },
          1194: {
            slidesPerView: 3,
          },
          2560: {
            slidesPerView: 3.5,
          },
        },
      }
    );
    var insta_swiper_container = new Swiper(
      ".swiper-container.insta_swiper_container",
      {
        navigation: {
          prevEl: ".insta_swiper_wrapper .left_arrow",
          nextEl: ".insta_swiper_wrapper .right_arrow",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 2.4,
            spaceBetween: 40,
          },

          1280: {
            spaceBetween: 40,
            slidesPerView: 3,
          },
        },
      }
    );
  }, [instapost]);

  useEffect(() => {
    document.querySelector(".px-0.MainNavBar.shadow-lg").style.display = "none";
    document.querySelector(".CyanBg.MainFooter").style.display = "none";
    if (body) {
      body.style.height = "auto";
    }

    var weekly_winner_swiper = new Swiper(
      ".swiper-container.weekly_winner_swiper",
      {
        navigation: {
          prevEl: ".weekly_winner_swiper .weekly_arrow_left",
          nextEl: ".weekly_winner_swiper .weekly_arrow_right",
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // autoHeight: true,
        // loop: true,
        breakpoints: {
          0: {
            // slidesPerView: 1.2,
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            // slidesPerView: 1.7,
            slidesPerView: 2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1195: {
            spaceBetween: 35,
            slidesPerView: 4,
          },
        },
      }
    );
    var mega_winner_swiper = new Swiper(
      ".swiper-container.mega_winner_swiper",
      {
        navigation: {
          prevEl: ".mega_winner_swiper .mega_arrow_left",
          nextEl: ".mega_winner_swiper .mega_arrow_right",
        },
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1.7,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1195: {
            spaceBetween: 35,
            slidesPerView: 4,
          },
        },
      }
    );
  }, [winnerType, instapost]);

  useEffect(() => {
    if (copyHashTag) {
      let copiedTimeout = setTimeout(() => {
        setCopyHashTag(null);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copyHashTag]);

  useEffect(() => {
    if (isMenuOpen) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const fetchInstaPost = async () => {
    try {
      const response = await axios.get(insta_api, {
        params: {
          fields: insta_fields,
          access_token: insta_token,
          limit: insta_limit,
        },
      });

      const { data } = response;

      if (data) {
        setinstapost(data.data);
      }
    } catch (error) {
      console.log("insta error", error.response);
    }
  };

  useEffect(() => {
    fetchInstaPost();
  }, []);

  const instapostlist = instapost.length
    ? instapost.map((data, key) => {
        return (
          <figure className="swiper-slide" key={key}>
            <a href={data.permalink} target="_blank">
              <img
                src={
                  data.media_type == "VIDEO"
                    ? data.thumbnail_url
                    : data.media_url
                }
                alt="Laxmi Instagram media"
                loading="lazy"
                className="instaimg"
              />
            </a>
          </figure>
        );
      })
    : null;

  const handleImageWrapperClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const handleCopyHashTag = (i) => {
    // const el = document.createElement("input");
    // el.value = hashTag;
    // document.body.appendChild(el);
    // el.select();
    // document.execCommand("copy");
    // document.body.removeChild(el);
    // navigator.clipboard.writeText(hashTag);
    setCopyHashTag(i);
  };
  // const applyScroll = (el, deskVal) => {
  //   const id = el;
  //   const yOffset = width >= 768 ? deskVal : -100;
  //   const element = document.getElementById(id);
  //   const y =
  //     element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  //   window.scrollTo({ top: y, behavior: "smooth" });
  // };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const hashtagList = hashtagData.map(({ hashTag, hashTagInfo }, i) => (
    <div className="part_box" key={i}>
      <div className="hashtag_heading">{hashTag}</div>
      <div className="hashtag_info_wrapper">
        <p className="hashtag_para para_desc">{parse(hashTagInfo)}</p>
        <CopyToClipboard text={hashTag}>
          <button
            className="hashtag_btn"
            onClick={() => handleCopyHashTag(i + 1)}
          >
            {copyHashTag == i + 1 ? (
              <span>Copied to clipboard</span>
            ) : (
              <>
                <img src={copyicon} alt="copy icon" loading="lazy" />
                <span>COPY HASTAG</span>
              </>
            )}
          </button>
        </CopyToClipboard>
      </div>
    </div>
  ));

  const testimonialList = testimonialData.map(
    ({ ytLink, img, testoDesc }, i) => (
      <div className="swiper-slide" key={i}>
        <div
          className="testimonial_box"
          onClick={() => handleImageWrapperClick(ytLink)}
        >
          <figure className="testimonial_img_wrapper">
            <img
              src={img}
              alt="Testimonial image"
              className="testimonial_img"
              loading="lazy"
              width={282}
              height={280}
            />
          </figure>
          <img src={whiteplayicon} alt="play icon" className="whiteplayicon" />
          <p className="testimonial_text para_desc">{testoDesc}</p>
        </div>
      </div>
    )
  );

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight != 0) {
      setIsLoader(false);
    }
  };
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  // };
  return (
    <>
      <MetaDecorator
        metaTitle="50 Years of Laxmi (HOS) - Mega Contest"
        metaDesc="Celebrate 50 years of bringing Laxmi home to you with us. Fill in the form and get a chance to win exciting prizes!"
        canonicalLink={window.location.href}
      />
      <div
        className="loader_wrapper"
        style={{ display: isLoader ? "flex" : "none" }}
      >
        <img src={Spinner} alt="spinner" />
      </div>
      <main className="landing_page_main">
        <header className="header">
          <img
            src={isMenuOpen ? cross : hamburger}
            alt="Hamburger Icon"
            className="hamburger_icon"
            onClick={() => handleMenuClick()}
          />
          <figure className="laxmilogo_wrapper">
            <a href="/">
              <img src={laxmilogo} alt="laxmi logo" className="laxmilogo" />
            </a>
          </figure>
          <div className={`nav_flex ${isMenuOpen ? "active" : ""}`}>
            <ul className="nav_links">
              <li>
                <ScrollLink
                  to="winnersec"
                  spy={true}
                  offset={-150}
                  duration={500}
                >
                  <span
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Winners
                  </span>
                </ScrollLink>
              </li>
              <li>
                <ScrollLink to="aboutsec" spy={true} offset={0} duration={500}>
                  <span
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    About Contest
                  </span>
                </ScrollLink>
              </li>
              {/* <li>
                <ScrollLink
                  to="gallerysec"
                  spy={true}
                  offset={-170}
                  duration={500}
                >
                  <span
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Gallery
                  </span>
                </ScrollLink>
              </li> */}
              <li>
                <ScrollLink
                  to="testimonialsec"
                  spy={true}
                  offset={-170}
                  duration={500}
                >
                  <span
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    Testimonials
                  </span>
                </ScrollLink>
              </li>
              <li>
                {" "}
                <a href="/terms-and-conditions" className="terms_anchor">
                  <span>T&C</span>
                </a>{" "}
              </li>
            </ul>
            <div className="social_icons">
              <a
                href="https://www.facebook.com/LaxmiFoodsBrand/"
                target="_blank"
              >
                <img
                  src={width >= 768 ? fbicon : whitefbicon}
                  alt="social media icon"
                />
              </a>
              <a
                href="https://www.instagram.com/laxmifoodsbrand/?hl=en"
                target="_blank"
              >
                <img
                  src={width >= 768 ? instaicon : whiteinstaicon}
                  alt="social media icon"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCA-ojVe3STlOlTOzGzZhYwQ/featured"
                target="_blank"
              >
                <img
                  src={width >= 768 ? youtubeicon : whiteyoutubeicon}
                  alt="social media icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/houseofspices"
                target="_blank"
              >
                <img
                  src={width >= 768 ? linkedinicon : whitelinkedinicon}
                  alt="social media icon"
                />
              </a>
            </div>
          </div>
        </header>
        <section className="bannersec">
          <img
            src={width >= 768 ? bannerimg : mbbannerimg}
            alt="Landing page banner"
            className="bannerimg"
            onLoad={imageLoaded}
          />
          {/* <div className="text_container">
            <p>SHARE YOUR STORY here</p>
            <figure className="bannerhand_wrapper">
              <img src={bannerhand} alt="Down arrow" />
            </figure>
            <ScrollLink to="formsec" spy={true} offset={0} duration={500}>
              <div className="participate_btn" role="button">
                <strong className="dsf">PARTICIPATE NOW</strong> <br />
                <span>FOR A CHANCE TO WIN</span>
              </div>
            </ScrollLink>
          </div> */}
        </section>
        <section className="aboutsec" id="aboutsec">
          <div className="my_container">
            <ScrollAnimation animateIn="fadeIn">
              <div className="about_flex">
                <div className="about_left">
                  <img
                    src={shilpaimg}
                    alt="About us image"
                    className="shilpaimg"
                    width={808}
                    height={794}
                    loading="lazy"
                  />
                  <img
                    src={aboutplayicon}
                    alt="About us play icon"
                    loading="lazy"
                    className="aboutplayicon"
                    onClick={() => {
                      handleImageWrapperClick(
                        "https://www.youtube.com/embed/JWApnwn-lbU"
                      );
                    }}
                  />
                  <div className="watch_strip">Watch my story here</div>
                </div>
                <div className="about_right">
                  <h1 className="sec_heading">About the Contest</h1>
                  <Curvedesign className="curved_design_logo" />
                  <div className="about_para para_desc">
                    Laxmi is proudly celebrating its 50 years of legacy of
                    creating memories and keeping food traditions alive in the
                    USA. <br />
                    <br />
                    To celebrate this occasion, we like to invite your stories &
                    unforgettable experiences that shaped your American journey
                    & how Laxmi was there to curate your Yadon ki Recipe. Please
                    upload a video{" "}
                    <ScrollLink
                      to="formsec"
                      spy={true}
                      offset={0}
                      duration={500}
                    >
                      <span className="here_link">here</span>{" "}
                    </ScrollLink>
                    with your story & get a chance to win exciting prizes - You
                    can win weekly gift vouchers, amazing merchandising &
                    opportunity to take away Grand Prize - iPhone 13
                    <br />
                    <br />
                    <span className="note_span">Note</span>: Participants are
                    eligible for winning the weekly contest. In case, the
                    participant won the Weekly Winners contest, he/she is still
                    eligible for winning the Mega & Grand Prizes.
                    <br />
                    <br />
                    <div className="rules_div">Rules For Participation:-</div>
                    <div>a) Use All The Three Hashtags:</div>
                    <ul>
                      <li>#LaxmiYadoonKiRecipe</li>
                      <li>#50YearsOfLaxmi</li>
                      <li>#ReachOutWithLaxmi</li>
                    </ul>
                    <div>b) Video Length- 60-120 seconds</div>
                    <div>c) Age Of Participation- 18 years and above</div>
                    <div>
                      d) Country Criteria- Only the Residents of the United
                      States can participate in the contest
                    </div>
                    <div>
                      e) Social Handles- Follow Laxmi on{" "}
                      <a
                        href="https://www.instagram.com/laxmifoodsbrand/?hl=en"
                        target="_blank"
                        className="here_link"
                      >
                        Instagram
                      </a>{" "}
                      &{" "}
                      <a
                        href="https://www.facebook.com/LaxmiFoodsBrand/"
                        target="_blank"
                        className="here_link"
                      >
                        Facebook
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </section>
        <ScrollAnimation animateIn="fadeIn">
          <section className="partsec">
            <div className="my_container">
              <img
                src={width >= 768 ? partdesignimg : mbpartdesignimg}
                alt="Use hashtag design image"
                className="partdesignimg"
                loading="lazy"
              />
              <div className="text_container">
                <h3 className="sec_heading">USE HASTAGS TO PARTICIPATE</h3>
                <Curvedesign className="curved_design_logo" />
              </div>
              <div className="part_flex">{hashtagList}</div>
            </div>
          </section>
        </ScrollAnimation>
        <div className="form_testi_gallery_sec">
          <ScrollAnimation animateIn="fadeIn">
            {/* <section className="formsec" id="formsec">
              <div className="my_container">
                <div className="text_container">
                  <h3 className="sec_heading">Fill the form to PARTICIPATE</h3>
                </div>
                <div className="form_flex">
                  <form
                    className="form_left"
                    spellCheck="false"
                    onSubmit={handleSubmit}
                  >
                    <div className="participate_form_flex">
                      <div className="field_box">
                        <input
                          type="text"
                          className="input_field"
                          placeholder=" "
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <label className="floting_label">
                          Full Name<span>*</span>
                        </label>
                        <div className="error">{formError.name}</div>
                      </div>
                      <div className="field_box">
                        <div className="mobile_field_box_flex">
                          <div className="country_code_select_wrapper">
                            <select className="input_field">
                              <option value="+1">+1</option>
                            </select>
                            <img
                              src={dropDownArrow}
                              alt=""
                              className="dropDownArrow"
                            />
                          </div>
                          <div className="mobile_field_box">
                            <input
                              type="tel"
                              className="input_field"
                              name="phone"
                              placeholder=" "
                              role="number"
                              value={values.phone}
                              onChange={handleChange}
                            />
                            <label className="floting_label">
                              Contact No<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="error">{formError.phone}</div>
                      </div>
                      <div className="field_box">
                        <input
                          type="text"
                          className="input_field"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label className="floting_label">
                          Email ID<span>*</span>
                        </label>
                        <div className="error">{formError.email}</div>
                      </div>
                      <div className="field_box">
                        <select
                          className="input_field_dropdown"
                          name="gender"
                          value={values.gender}
                          onChange={handleChange}
                          checkval={values.gender}
                        >
                          <option value=""></option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        <label className="floting_label">
                          gender <span>*</span>
                        </label>
                        <div className="error">{formError.gender}</div>
                        <img
                          src={dropDownArrow}
                          alt=""
                          className="dropDownArrow"
                        />
                      </div>

                      <div className="field_box">
                        <input
                          type="number"
                          min={1}
                          className="input_field"
                          name="age"
                          value={values.age}
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label className="floting_label">
                          Age<span>*</span>
                        </label>
                        <div className="error">{formError.age}</div>
                      </div>
                      <div className="field_box">
                        <input
                          type="text"
                          className="input_field"
                          name="contest_learning"
                          value={values.contest_learning}
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label className="floting_label">
                          Where did you learn about this contest?<span>*</span>
                        </label>
                        <div className="error">
                          {formError.contest_learning}
                        </div>
                      </div>
                      <div className="field_box">
                        <input
                          type="text"
                          className="input_field"
                          name="fav_product"
                          value={values.fav_product}
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label className="floting_label">
                          Which is your favorite Laxmi Product?<span>*</span>
                        </label>
                        <div className="error">{formError.fav_product}</div>
                      </div>
                      <div className="field_box">
                        <input
                          type="text"
                          className="input_field"
                          name="product_want"
                          value={values.product_want}
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label className="floting_label">
                          Which product you want to see from Laxmi?
                          <span>*</span>
                        </label>
                        <div className="error">{formError.product_want}</div>
                      </div>

                      <div className="field_box">
                        <textarea
                          className="input_field input_field_textarea"
                          placeholder=" "
                          name="story"
                          value={values.story}
                          onChange={handleChange}
                        ></textarea>
                        <label className="floting_label">
                          Share your story
                          <span>*</span>
                        </label>
                        <div className="error">{formError.story}</div>
                      </div>
                      {width >= 768 && <div className="field_box"></div>}
                      <div className="upload_file_wrapper">
                        <div className="upload_heading">
                          Upload your Photo/Video
                          <span style={{ color: "#cc121d" }}>*</span>
                        </div>
                        <input
                          type="file"
                          id="uploadFile"
                          hidden
                          name="media"
                          onChange={handleChange}
                        />
                        <label htmlFor="uploadFile" className="upload_label">
                          <img
                            src={uploadicon}
                            alt="upload icon"
                            className="uploadicon"
                            loading="lazy"
                          />
                          <span>Choose File</span>
                        </label>
                        <div className="upload_info_flex">
                          <p className="upload_info">
                            File type: jpeg, png, tiff, mp4
                          </p>
                          
                        </div>
                        <p className="file_name">{values?.media?.name}</p>
                        <p className="error">{formError.media}</p>
                      </div>
                      <div className="checkbox_flex">
                        <div className="checkbox_wrapper">
                          <input
                            type="checkbox"
                            id="termsAccept"
                            checked={isTermsAccept ? true : false}
                            onChange={() => setIsTermsAccept(!isTermsAccept)}
                            className="check_box"
                          />
                          <label
                            htmlFor="termsAccept"
                            className="label_text"
                          ></label>
                        </div>
                        <div className="checkbox_content">
                          By clicking the submit button below, I hereby agree to
                          and accept the following{" "}
                          <a
                            className="terms_link"
                            href="/terms-and-conditions"
                            target="_blank"
                          >
                            terms and conditions
                          </a>
                          &nbsp; governing my use of the shared stories on the
                          Laxmi's 50 years contest landing page. I further
                          reaffirm my acceptance of the general terms and
                          conditions.
                        </div>
                      </div>
                    </div>
                    <button className="participate_submit_btn" type="submit">
                      <span>
                        
                        {loading ? (
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "SUBMIT"
                        )}
                      </span>
                    </button>
                  </form>
                  <div className="form_right">
                    <img
                      src={formright}
                      alt="Form image"
                      className="formrightimg"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </section> */}
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <section className="testimonialsec" id="testimonialsec">
              <div className="text_container">
                <h3 className="sec_heading">View our testimonials</h3>
                <Curvedesign className="curved_design_logo" />
              </div>
              <div className="testimonial_swiper_wrapper">
                <div className="swiper-container testimonial_swiper">
                  <div className="swiper-wrapper">{testimonialList}</div>
                </div>
                {testimonialData.length > 3 || width <= 1193 ? (
                  <div className="navigation_wrapper">
                    <img
                      src={redleftarrow}
                      alt=""
                      className="testi_arrow left_arrow"
                      loading="lazy"
                    />
                    <img
                      src={redrightarrow}
                      alt=""
                      className="testi_arrow right_arrow"
                      loading="lazy"
                    />
                  </div>
                ) : null}

                <img
                  src={testidesign}
                  alt=""
                  className="testidesign testidesign1"
                  loading="lazy"
                />
                <img
                  src={testidesign}
                  alt=""
                  className="testidesign testidesign2"
                  loading="lazy"
                />
              </div>
            </section>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
            <section className="winnersec" to="winnersec">
              <img
                src={width >= 768 ? winnerbgimg : mbwinnerbgimg}
                alt=""
                className="winnerbgimg"
              />
              <div className="text_container">
                <div className="text_container">
                  <img
                    src={starimg}
                    alt=""
                    loading="lazy"
                    className="starimg"
                  />
                  <h3 className="sec_heading">Lucky winners</h3>
                  <div className="winner_btn_wrapper">
                    <button
                      className={`winner_btn ${
                        winnerType === "weekly-winner" ? "active" : ""
                      }`}
                      onClick={() => setWinnerType("weekly-winner")}
                    >
                      weekly winners
                    </button>
                    {/* <button
                      className={`winner_btn ${
                        winnerType === "mega-winner" ? "active" : ""
                      }`}
                      onClick={() => setWinnerType("mega-winner")}
                    >
                      mega winners
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="winner_swipers">
                {
                  winnerType === "weekly-winner" ? (
                    <div
                      className={`weekly_winner_swiper swiper-container ${
                        winnerType === "weekly-winner" ? "active" : ""
                      }`}
                    >
                      <div className="swiper-wrapper">
                        {weeklyWinners.map(({ winnerName }, i) => (
                          <div className="swiper-slide" key={i}>
                            <div className="winner_box">
                              {/* <img
                                src={winnerimg1}
                                alt="Winner image"
                                className="winnerimg"
                                loading="lazy"
                              /> */}
                              <p>{winnerName}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="navigation_wrapper">
                        <img
                          src={whitearrowleft}
                          alt=""
                          className="winner_arrow left_arrow weekly_arrow_left"
                          loading="lazy"
                        />
                        <img
                          src={whitearrowright}
                          alt=""
                          className="winner_arrow right_arrow weekly_arrow_right"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  ) : null
                  // <div
                  //   className={`mega_winner_swiper swiper-container ${
                  //     winnerType === "mega-winner" ? "active" : ""
                  //   }`}
                  // >
                  //   <div className="swiper-wrapper">
                  //     <div className="swiper-slide">
                  //       <div className="winner_box">
                  //         <img
                  //           src={winnerimg2}
                  //           alt="Winner image"
                  //           className="winnerimg"
                  //           loading="lazy"
                  //         />
                  //         <p>Lorem ipsum</p>
                  //       </div>
                  //     </div>
                  //     <div className="swiper-slide">
                  //       <div className="winner_box">
                  //         <img
                  //           src={winnerimg2}
                  //           alt="Winner image"
                  //           className="winnerimg"
                  //           loading="lazy"
                  //         />
                  //         <p>Lorem ipsum</p>
                  //       </div>
                  //     </div>
                  //     <div className="swiper-slide">
                  //       <div className="winner_box">
                  //         <img
                  //           src={winnerimg2}
                  //           alt="Winner image"
                  //           className="winnerimg"
                  //           loading="lazy"
                  //         />
                  //         <p>Lorem ipsum</p>
                  //       </div>
                  //     </div>
                  //     <div className="swiper-slide">
                  //       <div className="winner_box">
                  //         <img
                  //           src={winnerimg2}
                  //           alt="Winner image"
                  //           className="winnerimg"
                  //           loading="lazy"
                  //         />
                  //         <p>Lorem ipsum</p>
                  //       </div>
                  //     </div>
                  //     <div className="swiper-slide">
                  //       <div className="winner_box">
                  //         <img
                  //           src={winnerimg2}
                  //           alt="Winner image"
                  //           className="winnerimg"
                  //           loading="lazy"
                  //         />
                  //         <p>Lorem ipsum</p>
                  //       </div>
                  //     </div>
                  //   </div>
                  //   <div className="navigation_wrapper">
                  //     <img
                  //       src={whitearrowleft}
                  //       alt=""
                  //       className="winner_arrow left_arrow mega_arrow_left"
                  //       loading="lazy"
                  //     />
                  //     <img
                  //       src={whitearrowright}
                  //       alt=""
                  //       className="winner_arrow right_arrow mega_arrow_right"
                  //       loading="lazy"
                  //     />
                  //   </div>
                  // </div>
                }
              </div>
            </section>
          </ScrollAnimation>

          {instapost.length > 0 && (
            <ScrollAnimation animateIn="fadeIn">
              <section className="instasec">
                <img src={instarightdot} alt="" className="instarightdot" />
                <img src={instaleftdot} alt="" className="instaleftdot" />
                <div className="text_container">
                  <h3 className="sec_heading">Laxmi's Life on instagram</h3>
                  <Curvedesign className="curved_design_logo" />
                </div>
                <div className="insta_swiper_wrapper">
                  <div className="swiper-container insta_swiper_container">
                    <div className="swiper-wrapper">{instapostlist}</div>
                  </div>
                  <div className="navigation_wrapper">
                    <img
                      src={redleftarrow}
                      alt=""
                      className="testi_arrow left_arrow"
                      loading="lazy"
                    />
                    <img
                      src={redrightarrow}
                      alt=""
                      className="testi_arrow right_arrow"
                      loading="lazy"
                    />
                  </div>
                </div>
              </section>
            </ScrollAnimation>
          )}
          {/* <ScrollAnimation animateIn="fadeIn">
            <section className="gallerysec" id="gallerysec">
              <div className="text_container">
                <h3 className="sec_heading">SEE OUR GALLERY</h3>
                <Curvedesign className="curved_design_logo" />
              </div>
              <div className="gallery_images_wrapper">
                <div className="gallery_grid">
                  {galleryData.map((el, i) => (
                    <figure className="galleryimg_wrapper" key={i}>
                      <img
                        src={el.img}
                        alt="Gallery image"
                        loading="lazy"
                        className="galleryimg"
                      />
                      {el.mediaType === "video" ? (
                        <img
                          src={galleryplayicon}
                          alt=""
                          loading="lazy"
                          className="galleryplayicon"
                          onClick={() => handleImageWrapperClick(el.ytLink)}
                        />
                      ) : null}
                    </figure>
                  ))}
                </div>
              </div>
            </section>
          </ScrollAnimation> */}
        </div>

        <LandingPageFooter />
        {/* <footer className="footer">
        <div className="my_container">
          <ul className="social_media_wrapper">
            <li>
              <a href="#" target="_blank">
                <img src={fbicon} alt="social media icon" loading="lazy" />
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <img src={instaicon} alt="social media icon" loading="lazy" />
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <img src={youtubeicon} alt="social media icon" loading="lazy" />
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <img
                  src={linkedinicon}
                  alt="social media icon"
                  loading="lazy"
                />
              </a>
            </li>
          </ul>
          <div className="links_wrapper">
            <span>
              <a href="#">our story</a>
            </span>
            <span>
              <a href="#">our products</a>
            </span>
            <span>
              <a href="#">store locator</a>
            </span>
            <span>
              <a href="#">laxmi's recipe's</a>
            </span>
            <span>
              <a href="#">media</a>
            </span>
            <span>
              <a href="#">blogs</a>
            </span>
            <span>
              <a href="#">contact us</a>
            </span>
          </div>
          <div className="terms_row">
            <span>Copyrights Reserved @Laxmi</span>
            <span>
              Crafted by{" "}
              <a href="https://www.togglehead.in/" target="_blank">
                @Togglehead.in
              </a>{" "}
            </span>
          </div>
        </div>
      </footer>  */}
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
        {/* <MyVerticallyCenteredModal
          video={vidLink}
          show={isVideoModalOpen}
          onHide={() => setIsVideoModalOpen(false)}
        /> */}
      </main>
      <GoToTop />
    </>
  );
};

export default LandingPage;
