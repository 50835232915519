import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MetaDecorator from "../../../componentsnew/MetaDecorator/MetaDecorator";
import {
  circlegift,
  diwalibanner,
  diwalibannermob,
  fiftyyears,
  grandprice,
  goldenbonanza,
  chancetowin,
  entercoupon,
  shilpa,
  diwalibannernew,
  diwalibannernewmobile,
  megabannernew,
  megabannernewmobile,
} from "../../../images";
import useWindowDimensions from "../../../useWindowDimensions";
import Diwaliheader from "../DiwaliHeader/Diwaliheader";
import "./banner.scss";
import "swiper/swiper-bundle.css";
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
} from "swiper";
import AOS from "aos";
import "aos/dist/aos.css";
import { weeklyWinners } from "../DiwaliData";
import { diwaliform, diwaliwinner } from "../../../helpers/constant-words";
import GoToTop from "../../../components/GoToTop";
import "animate.css";

Swiper.use([Navigation, Pagination, Autoplay, Thumbs, EffectFade]);

const Banner = () => {
  const { width } = useWindowDimensions();
  const [isLoader, setIsLoader] = useState(true);
  const [winnerType, setWinnerType] = useState("weekly-winner");

  useEffect(() => {
    const diwaliwinnerswiper = new Swiper(".diwali-winner-swiper", {
      slidesPerView: "auto",
      loop: true,
      freeMode: true,
      loopedSlides: weeklyWinners.length, //how many slides it should duplicate
      spaceBetween: 0,
      centeredSlides: true,
      speed: 6000,
      autoplay: {
        delay: 1,
      },
      allowTouchMove: false,
      disableOnInteraction: true,
    });
  });

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight != 0) {
      setIsLoader(false);
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <MetaDecorator
        metaTitle="Laxmi (HOS) - Golden Jubilee Bonanza"
        metaDesc="Enter Coupon Code for a chance to win prizes upto 20,000 USD in Laxmi's Golden Jubilee Bonanza."
        canonicalLink={window.location.href}
      />
      <Diwaliheader />
      <section
        className="diwali_bannersec"
        data-aos="fade"
        data-aos-duration="2000"
      >
        <Link to={diwaliwinner}>
          {/* <img
            src={width >= 768 ? diwalibanner : diwalibannermob}
            alt="Landing page banner"
            className="bannerimg"
            onLoad={imageLoaded}
          /> */}
          <img
            src={width >= 768 ? megabannernew : megabannernewmobile}
            alt="Landing page banner"
            className="bannerimg"
            onLoad={imageLoaded}
          />
          <div className="text_container">
            <figure className="bannerhand_wrapper">
              <img src={circlegift} alt="Down arrow" />
            </figure>
          </div>

          {/* <div className="grand-price">
            <figure className="grandprice_wrapper">
              <img src={grandprice} alt="grand price" />
            </figure>
          </div>

          <div className="chance-to-win">
            <figure className="chancetowin_wrapper">
              <img src={chancetowin} alt="chance-tow-in" />
            </figure>
          </div>

          <div className="golden-bonanza">
            <figure className="goldenbonanza_wrapper">
              <img src={goldenbonanza} alt="text" />
            </figure>
          </div>

          <div className="enter-coupon">
            <figure className="entercoupon_wrapper">
              <img src={entercoupon} alt="coupon" />
            </figure>
          </div> */}

          <div className="fifty-years">
            <figure className="fiftyyears_wrapper">
              <img src={fiftyyears} alt="50 years" />
            </figure>
          </div>

          {/* <div className="shilpa animate__animated animate__zoomIn">
            <figure className="shilpa_wrapper">
              <img src={shilpa} alt="shilpa img" />
            </figure>
          </div> */}
        </Link>
        {/* <div className="diwali_winner_swipers">
          <div className="winner_heading">
            WEEKLY <br />
            WINNERS:
          </div>
          {winnerType === "weekly-winner" ? (
            <div
              className={`diwali-winner-swiper swiper-container ${
                winnerType === "weekly-winner" ? "active" : ""
              }`}
            >
              <div className="swiper-wrapper">
                {weeklyWinners.map(({ winnerName }, i) => (
                  <div className="swiper-slide" key={i}>
                    <div className="winner_box">
                      <p>{winnerName},</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div> */}
      </section>
      <GoToTop />
    </>
  );
};

export default Banner;
