import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./megawinner.scss";
import {
  circlegift,
  diwalibanner,
  diwalibannermob,
  goldvoucher,
  megashilpa,
  megawinnerback,
  newvoucherwinner,
  voucherwinnerpng,
} from "../../../images";
import useWindowDimensions from "../../../useWindowDimensions";
import Diwaliheader from "../DiwaliHeader/Diwaliheader";

import GoToTop from "../../../components/GoToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";

//Images
import {
  shilpawinner,
  shilpawinnermob,
  voucherwinner,
  coin,
  goldwinnerback,
  downarrow,
} from "../../../images";
import MetaDecorator from "../../../componentsnew/MetaDecorator/MetaDecorator";
import { megawinnerData, winnerData } from "../DiwaliData";
function MegaWinner() {
  const { width } = useWindowDimensions();
  const [weekTabIndex, setWeekTabIndex] = useState(0);

  //logical functions
  const handleWeekTabChange = (e) => {
    const activeTabId = e.target.selectedOptions[0].dataset.weektabindexid;
    setWeekTabIndex(Number(activeTabId));
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const winnersList = megawinnerData.map(({ winners }, i) => (
    <>
      <div className={`name_section ${weekTabIndex === i ? "active" : ""}`}>
        {winners.map((winner, i) => (
          <div className="name" key={i}>
            <img
              className="first_child"
              src={width > 600 ? goldvoucher : goldvoucher}
            />
            <img
              className="not_first"
              src={width > 600 ? newvoucherwinner : voucherwinnerpng}
            />
            <p
              className="name_disp"
              dangerouslySetInnerHTML={{ __html: winner.toLowerCase() }}
            ></p>
          </div>
        ))}
      </div>
    </>
  ));

  const weekTabsList = winnerData.map(({ tabName }, i) => (
    <option className="options" value={tabName} key={i} data-weektabindexid={i}>
      {tabName}
    </option>
  ));

  return (
    <>
      <MetaDecorator
        metaTitle="Laxmi (HOS) - Winners:  Golden Jubilee Bonanza"
        metaDesc=""
        canonicalLink={window.location.href}
      />
      <Diwaliheader />
      <section
        className="megawinner_sec"
        data-aos="fade"
        data-aos-duration="2000"
      >
        <div className="my_container">
          <div className="title">
            <h1>WINNERS</h1>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-8">
              <div className="winner_names_outer">
                <div className="winner_names">
                  <div className="scroll_desc">Scroll to see all winners.</div>
                  <div className="week_dropdown">
                    <div className="dropdown_content">
                      <select className="week" onChange={handleWeekTabChange}>
                        {weekTabsList}
                      </select>
                      <img src={downarrow} className="downarrow" />
                    </div>
                  </div>
                  {winnersList}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4">
              <div className="mega_winner_outer">
                <img src={megashilpa} className="mega_shilpa" />
                <div className="mega_heading">Mega Prize</div>
                <div className="mega_winner_box">
                  <img src={megawinnerback} className="mega_winner_back" />
                  <div className="mega_winner_name">
                    Chitti Mohana Laxmi Sanku
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  );
}

export default MegaWinner;
