import "./diwalithankyou.scss";

import React, { useEffect } from "react";
import Diwaliheader from "../DiwaliHeader/Diwaliheader";
import { leftarrow } from "../../../images";
import { Link } from "react-router-dom";
import { diwali } from "../../../helpers/constant-words";
import AOS from "aos";
import "aos/dist/aos.css";

const DiwaliThankyou = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Diwaliheader />
      <div className="diwali_thankyousec">
        <div
          className="thankyou_wrapper"
          data-aos="fade"
          data-aos-duration="2000"
        >
          <div className="thankyou_content">
            <h1 className="thank_heading">THANK YOU! </h1>
            <p className="thankyoudesc">
              Dear Laxmi Customer, <br />
              Thank you for being a part of Laxmi's Diwali Golden Jubilee
              Bonanza and for your continued patronage! <br />
              <br />
              Visit the website every week 26th onwards to know if you're the
              lucky one!
            </p>
            {/* <button className="thankyou_btn"></button> */}
            <Link to={diwali}>
              <button href type="button" className="thankyou_btn">
                <img
                  className="left_arrow_thank"
                  src={leftarrow}
                  alt="left arrow"
                />
                Back to Homepage
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiwaliThankyou;
