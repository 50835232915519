import React from "react";
import title_symbol_black from "../../images/homepage/title_symbol_black.png";
import Accordion from "./Accordion";
import "./Faqpage.scss";
import MetaDecorator from "../../componentsnew/MetaDecorator/MetaDecorator";

const Faqpage = () => {
  return (
    <>
      <MetaDecorator
        metaTitle="Laxmi (HOS) - Frequently Asked Questions (FAQs)"
        metaDesc="Learn in depth about laxmi products. Get nutritional value and much more. Click here to know more."
        canonicalLink="https://laxmihos.com/faq"
      />
      <section className="faqpagesec1">
        <div className="heading text-center">FAQ</div>
        <div className="text-center">
          <img
            className="imgsym"
            src={title_symbol_black}
            alt="tower img"
            loading="lazy"
          />
        </div>
        <Accordion />
      </section>
    </>
  );
};

export default Faqpage;
