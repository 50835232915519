import axios from "axios";

import { toast } from "react-toastify";


import config from "../config/config";


const helper = {
  validateEmail: async (email, modelName) => {
    try {
      const { data: res } = await axios.post(
        `${config.api_url}general/email-validation`,
        {
          email,
          modelName,
        }
      );

      if (res.status !== 200) {
        return false;
      }
      return true;
    } catch (error) {
      toast.error("Internal Server Error");
      console.log("error", error.response);
      //   return false;
    }
  },

  validateCode: async (code) => {
    try {
      const { data: res } = await axios.post(
        `${config.api_url}/forms/diwali-bonanza/code-validate`,
        {
          code,
         
        }
      );

      if (res.status !== 200) {
        return false;
      }
      return true;
    } catch (error) {
      toast.error("Internal Server Error");
      console.log("error", error.response);
      //   return false;
    }
  },


};
export default helper;


