import React from "react";

import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <Container fluid className="CyanBg MainFooter">
      <Row className="justify-content-center">
        <Col className="col-lg-3 mobile-left-leaf px-0">
          <img
            src={process.env.PUBLIC_URL + "/images/white-leaf.png"}
            alt="whiteLeaf"
            className="left-leaf leaf-img img-fluid"
          />
        </Col>
        <Col className="col-lg-6 socialMedia">
          <Row>
            <div className="emailPhoneContainer">
              <div className="emailCol">
                <img
                  src={process.env.PUBLIC_URL + "/images/emailIcon.svg"}
                  alt="Email"
                  className="emailIcon img-fluid"
                />
                <div className="px-2  emailIdCol">
                  <span>Email id</span>
                  <span>
                    {" "}
                    <a
                      href="mailto:customerservice@houseofspicesindia.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      customerservice@houseofspicesindia.com
                    </a>
                  </span>
                </div>
              </div>
              <div className="phoneCol">
                <img
                  src={process.env.PUBLIC_URL + "/images/phone-callIcon.svg"}
                  alt="Email"
                  className="phoneIcon emailIcon img-fluid"
                />
                <div className="px-2 phoneNumberCol">
                  <span>Phone No.</span>
                  <span>
                    {" "}
                    <a href="tel:18334652964">1-833-GOLAXMI</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="socialLinksContainer">
              <div className="socialLinksItem">
                <a
                  href="https://www.facebook.com/LaxmiFoodsBrand/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/facebook.svg"}
                    alt="whiteLeaf"
                    className="footerIcon img-fluid"
                  />
                </a>
              </div>
              <div className="socialLinksItem">
                <a
                  href="https://www.instagram.com/laxmifoodsbrand/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/instagram.svg"}
                    alt="whiteLeaf"
                    className="footerIcon img-fluid"
                  />
                </a>
              </div>
              <div className="socialLinksItem">
                <a
                  href="https://www.youtube.com/channel/UCA-ojVe3STlOlTOzGzZhYwQ/featured"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/youtube.svg"}
                    alt="whiteLeaf"
                    className="footerIcon img-fluid"
                  />
                </a>
              </div>
              <div className="socialLinksItem">
                <a
                  href="https://www.linkedin.com/company/houseofspices"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/linkedin.svg"}
                    alt="whiteLeaf"
                    className="footerIcon img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="policyContainer">
              {/* <span className="refLinks">
                <a href="javascript:void(0);" target="_blank">
                  Terms and Conditions
                </a>
              </span>
              <span className="footerBar">|</span> */}
              <span className="refLinks">
                <a
                  href="https://hosindia.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
              <span className="footerBar">|</span>
              <span className="refLinks">Copyrights Reserved @Laxmi</span>
              {/* <span className="footerBar">|</span>
              <span className="refLinks">
                Crafted by{" "}
                <a
                  href="https://www.togglehead.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Togglehead.
                </a>
              </span> */}
            </div>
          </Row>
        </Col>
        <Col className="col-lg-3 mobile-right-leaf px-0">
          <img
            src={process.env.PUBLIC_URL + "/images/right-leaf.png"}
            alt="whiteLeaf"
            className="right-leaf leaf-img img-fluid"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;

// <img
// src={process.env.PUBLIC_URL + "/images/white-leaf.png"}
// alt="whiteLeaf"
// className="btm-left-leaf"
// />
// <img
// src={process.env.PUBLIC_URL + "/images/right-leaf.png"}
// alt="whiteLeaf"
// className="btm-right-leaf"
// />
